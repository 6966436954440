import PropTypes from "prop-types"
import React from "react"

import styled from 'styled-components';
import { Link } from "gatsby";

import bgImage from '../static/images/hp-background.jpg';
import logo from '../static/images/logo.png';
import logoSubpage from '../static/images/logo_circle_black.png';
import Navigation from './Navigation';

const StyledHeader = styled.header.attrs({
    className: 'd-flex flex-column align-items-center justify-content-center pt-5 pb-1'
})`
    ${props => !props.subpage && `
        height: 100vh;
        background: url(${bgImage}) center no-repeat;
        background-size: cover;
        padding: 2rem!important;
    `}
`

const Logo = styled.img.attrs({
    src: logo,
    alt: 'Všude kytky logo'
})`
  width: 566px;
`

const LogoSubpage = styled.img.attrs({
    src: logoSubpage,
    alt: 'Všude kytky logo'
})`
    width: 193px;
`

const Header = ({ siteTitle, subpage }) => (
    <>
        <StyledHeader subpage={subpage}>
            <Link to="/">
                {subpage ? <LogoSubpage /> : <Logo />}
            </Link>
            {!subpage && <Navigation />}
        </StyledHeader>
        <Navigation primary />
    </>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
    subpage: PropTypes.bool,
}

Header.defaultProps = {
    siteTitle: ``,
    subpage: false,
}

export default Header
