import { css } from 'styled-components'

const sizes = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
}

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
         ${css(...args)};
      }
   `
    return acc
}, {})

const theme = {
    colors: {
        secondary: '#23302a',
        text_default: '#000',
        white: '#fff',
        black: '#000',
        bg_primary: '#f2ece3',
        bg_secondary: '#cabeb7',
        ash: '#c8c1b7',
        westar: '#dfd9d1',
        swirl: '#d1cbc3',
        hover: '#bd9659',
        button: '#b3a399',
    },
    fonts: {
        primary: 'EB Garamond',
        secondary: 'Canela',
    },
    sizes,
    media,
};



export default theme
