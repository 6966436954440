import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import styled from 'styled-components'

import navData from '../config/navData'

const NavWrapper = styled.div.attrs({
    className: 'py-4 d-none d-md-block'
})`
    ${props => props.primary && `
        background-color: ${props.theme.colors.bg_primary};
        border-bottom: 1px solid ${props.theme.colors.westar};
        position: sticky;
        top: 0;
        text-transform: uppercase;
        letter-spacing: 0.35em;
        z-index: 999;
    `}

    font-family: ${props => props.primary ? props.theme.fonts.primary : props.theme.fonts.secondary};
    font-size: ${props => props.primary ? '0.875rem' : '2.4rem'};
    color: ${props => props.primary ? props.theme.colors.text_default : '#fff'};

    ${props => props.theme.media.xxl`
        font-size: ${!props.primary && '3.75rem'};
   `}

    a {
        padding: .5rem 3rem;
        color: ${props => props.primary ? props.theme.colors.text_default : '#fff'};

        &:hover {
            color: ${props => props.theme.colors.hover}
        }
    }
`

const NavItem = styled.li.attrs({
    className: 'nav-item',
})`
    position: relative;

    &:after {
        content: '/';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%)
    }

    &:last-of-type:after {
        display: none;
    }
`

const Navigation = ({ primary }) => (
    <NavWrapper primary={primary}>
        <ul className="nav justify-content-center">
            {
                navData.map(({ id, title, url, targetBlank }) => (
                    <NavItem key={id}>
                        {targetBlank
                            ? <a className="nav-link" href={url} target="_blank" rel="noopener noreferrer">{title}</a>
                            : <Link className="nav-link" to={url}>{title}</Link>
                        }
                    </NavItem>
                ))
            }
        </ul>
    </NavWrapper>
)

Navigation.defaultProps = {
    primary: false,
}

Navigation.propTypes = {
    primary: PropTypes.bool,
}

export default Navigation
