/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import theme from '../config/theme'
import Header from './Header'
import Footer from './Footer'
import NavigationMobile from './NavigationMobile'
import GlobalStyle from '../config/GlobalStyles'

const Layout = ({ children, subpage }) => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NavigationMobile />
      <Header subpage={subpage} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  subpage: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  subpage: PropTypes.bool
}

export default Layout
