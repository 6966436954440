import React from "react"
import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"
import styled from 'styled-components'

import navData from '../config/navData'

const MenuWrapper = styled.div`
    ${({ theme }) => theme.media.md`
      display: none;
   `}

   font-family: ${({ theme }) => theme.fonts.secondary};

   nav {
       outline: none;
   }

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        right: 36px;
        top: 36px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: ${({ theme }) => theme.colors.button};
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: ${({ theme }) => theme.colors.hover};
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: ${({ theme }) => theme.colors.button};
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background-color: ${({ theme }) => theme.colors.ash};
        padding: 2.5em 1.5em 0;
        font-size: 2rem;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`

const NavigationMobile = () => (
    <MenuWrapper>
        <Menu right>
            <nav className="nav flex-column">
                {
                    navData.map(({ id, title, url, targetBlank }) => targetBlank
                        ? <a key={id} className="nav-link" href={url} target="_blank" rel="noopener noreferrer">{title}</a>
                        : <Link key={id} className="nav-link" to={url}>{title}</Link>
                    )
                }
            </nav>
        </Menu>
    </MenuWrapper>
)

export default NavigationMobile
