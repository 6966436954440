const navData = [
    {
        id: 'home',
        title: 'Domů',
        url: '/',
    },
    {
        id: 'portfolio',
        title: 'Portfolio',
        url: '/portfolio',
    },
    {
        id: 'o_mne',
        title: 'O mně',
        url: '/o-mne',
    },
    {
        id: 'kontakt',
        title: 'Kontakt',
        url: '/kontakt',
    },
]

export default navData
