import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html, body {
        -webkit-font-smoothing:antialiased;
        -moz-osx-font-smoothing:grayscale;
    }

    body {
        font-family: ${props => props.theme.fonts.primary};
        background-color: ${props => props.theme.colors.bg_primary};
        font-size: 16px;
    }

    a {
        color: ${props => props.theme.colors.text_default};
        transition: all 0.25s ease-in;

        &:hover {
            color: ${props => props.theme.colors.hover};
            text-decoration: none;
        }
    }

    .hero-text {
        font-size: 1.7rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;
        letter-spacing: 0.025em;
        line-height: 1.2;

        ${({ theme }) => theme.media.md`
            font-size: 2.4rem;
        `}
    }

    .subtitle {
        text-transform: uppercase;
        font-size: 0.875rem;
        letter-spacing: 0.35em;
    }

    img {
        max-width: 100%;
    }

    .lightbox-portal {
        position: relative;
        z-index: 999;
    }
`

export default GlobalStyle
