import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import logo from '../static/images/logo_circle_white.png'

const StyledFooter = styled.footer`
    background-color: ${({ theme }) => theme.colors.ash};
    color: #fff;

    a {
        color: #fff;
        &:hover {
            color: ${({ theme }) => theme.colors.hover};
        }
    }
`

const LogoFooter = styled.img.attrs({
    className: 'mb-4',
    src: logo,
    alt: 'Všude kytky logo'
})`
  height: 195px;
`

const CopyrightWrapper = styled.div.attrs({
    className: 'p-4 text-center'
})`
    border-top: 1px solid ${({ theme }) => theme.colors.swirl};
`

const ContactDetails = styled.div.attrs({
    className: 'd-flex flex-column flex-lg-row subtitle',
})``

const Detail = styled.div.attrs({
    className: 'mb-4 mb-lg-0 flex-grow-1',
})``

const Footer = () => {
    const data = useStaticQuery(graphql`
    {
      prismic {
        allContacts {
          edges {
            node {
              email
              instagram_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              instagram_name
              facebook_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook_name
              phone
            }
          }
        }
      }
    }
  `)

    const { email, phone, instagram_name, instagram_link, facebook_name, facebook_link } = data.prismic.allContacts.edges[0].node

    return (
        <StyledFooter>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col text-center py-5">
                        <LogoFooter />
                        <p className="hero-text mb-5">
                            Zaujala vás nabídka?<br />
                            Napište mi:
                        </p>
                        <ContactDetails>
                            <Detail>
                                <svg height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" className="svg-inline--fa fa-mobile-alt fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path></svg>
                                <div className="mt-1">{phone}</div>
                            </Detail>
                            <Detail>
                                <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                                    <svg width="20" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                                    <div className="mt-1">{email}</div>
                                </a>
                            </Detail>
                            <Detail>
                                <a href={instagram_link.url} target="_blank" rel="noopener noreferrer">
                                    <svg width="21" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                                    <div className="mt-1">{instagram_name}</div>
                                </a>
                            </Detail>
                            <Detail>
                                <a href={facebook_link.url} target="_blank" rel="noopener noreferrer">
                                    <svg height="20" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                    <div className="mt-1">{facebook_name}</div>
                                </a>
                            </Detail>
                        </ContactDetails>
                    </div>
                </div>
            </div>
            <CopyrightWrapper>
                &copy; 2020 | Veronika Klímová. All Rights Reserved.
            </CopyrightWrapper>
        </StyledFooter>
    )
}

export default Footer
